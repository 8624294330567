import React, { useState, useEffect, useContext } from "react";
import QrReader from "react-qr-reader";
import "./QRScanner.scss";
import { useNavigate } from "react-router-dom";
import closeBtn from "../assets/images/cclogout.svg";
import { ClickSourceContext } from "../context/clickSourceContext";
import { useTranslation } from "react-i18next";
import upArrow from "../assets/images/arrow-gif.gif";
import { LanguageContext } from "../context/languageContext";

const QrScanner = ({ setWalletAddress }) => {
  const [showDialog, setDiaglog] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [addressError, setaddressError] = useState(null);
  const navigate = useNavigate();
  const sourceCtx = useContext(ClickSourceContext);

  const langCtx = useContext(LanguageContext);

  const { t } = useTranslation();

  const handleScan = (scanData) => {
    if (scanData) {
      const regexForUrl =
        /(ethereum:|.*\?.*address=)?(0x[a-fA-F0-9]{40})(@[0-9]+)?$/;
      try {
        const checkedUrl = scanData.match(regexForUrl)[0];
        const regexForAddress = /0x[a-fA-F0-9]{40}/;
        const extractedAddress = checkedUrl.match(regexForAddress)[0];

        const address = extractedAddress.toLowerCase();
        setWalletAddress(address);
        sourceCtx.setScannedAddress(address);
        localStorage.setItem("address", address);
        navigate(`/wallet?address=${address}`);
      } catch (error) {
        setaddressError(error);
        setTimeout(() => {
          navigate("/home");
        }, 5000);
      }
    }
  };

  const handleNavigate = () => {
    navigate("/home");
  };

  const onError = () => {
    console.log("scanner error");
  };

  return (
    <>
      {!addressError ? (
        <div className="scanner-holder">
          <button className="qr-close-btn" onClick={handleNavigate}>
            <img src={closeBtn} />
          </button>

          <div className="scan-instructions">
            <img src={upArrow} className="up-arrow"></img>
            <p className="scanner-text">{t("scannerText")}</p>
          </div>

          {!showDialog && !processing && (
            <QrReader
              delay={500}
              onScan={handleScan}
              constraints={{
                facingMode: "environment",
              }}
              className="qr-reader"
              onError={onError}
            />
          )}
        </div>
      ) : (
        <div className="addressError">{t("qrError")}</div>
      )}
    </>
  );
};

export default QrScanner;
