import React, { useCallback, useContext } from 'react';
import '../App.css';
import logo from '../assets/images/logo.svg';
import arrow from '../assets/images/arrow.png';
import huIcon from '../assets/images/HU.png';
import LanguagePicker from './LanguagePicker';
import cclogo from '../assets/images/CamelCoding-logo.svg';
import { useNavigate } from 'react-router-dom';
import roLogo from '../assets/images/ro_color.svg';
import enLogo from '../assets/images/en_color.svg';
import { LanguageContext } from '../context/languageContext';
import ButtonGroup from './button-group/ButtonGroup';

const Header = ({ crest = null, address = null }) => {
  const navigate = useNavigate();
  const langCtx = useContext(LanguageContext);

  const goToHomepage = () => {
    navigate('/home');
  };

  return (
    <div className='header'>
      <div className='logo-div'>
        <img
          src={
            langCtx.language === 'hu'
              ? logo
              : langCtx.language === 'ro'
              ? roLogo
              : enLogo
          }
          className='logo'
          onClick={goToHomepage}
        ></img>
      </div>
      <div className='header-right-side'>
        <LanguagePicker />
        <ButtonGroup crest={crest} address={address}/>
      </div>
    </div>
  );
};

export default Header;
