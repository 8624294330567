import { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { LanguageContext } from "../../context/languageContext";
import Footer from "../../components/Footer";
import config from "../../config.json";
import { CardContext } from "../../context/CardContext";
import Header from "../../components/Header";
import { useExperienceCards } from "../../components/useExperienceCards";
import vectorBack from "../../assets/images/back-btn-vector.svg";
import { ClipLoader } from "react-spinners";

const NewInsignePage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const address = searchParams.get("address");
  const { usersCrest } = useExperienceCards(address);
  const { balanceOfOwnedCards } = useContext(CardContext);
  const { t } = useTranslation();
  const langCtx = useContext(LanguageContext);
  const { insigneTokenURI } = useContext(CardContext);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  console.log(balanceOfOwnedCards, "balance of cards");

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };
  //#region left text side
  const textSide = () => {
    return (
      <div className="text-side">
        <ul>
          {/* li */}
          <li
            className="card-text-normal"
            style={{
              fontSize: "14px",
              textTransform: "uppercase",
              color: config.MUZEUM.greyColor,
            }}
          >
            {t("ownedExperiences")}:{" "}
          </li>
          <li className="card-text-normal">
            <span
              className="text-value"
              style={{
                color: config.MUZEUM.textColor,
              }}
            >
              {balanceOfOwnedCards ? balanceOfOwnedCards : localStorage.getItem("balanceOf")}
            </span>
          </li>
          <hr
            style={{
              opacity: "0.3",
            }}
          />
          {/* li */}
          <li
            className="card-text-normal"
            style={{ marginTop: "20px", color: config.MUZEUM.textColor }}
          >
            {t("crestDescription")}
          </li>
          <div className="crest-buttons-container">
            {/* temporarly deleted 'edit crest' button */}
            {/* <div>
              <button
                onClick={() => window.open("https://insigne.csikimuzeum.ro/")}
                className="primary-button"
                style={{
                  color: config.MUZEUM.textColor,
                  border: `1px solid ${config.MUZEUM.textColor}`,
                  marginTop: "20px",
                }}
              >
                {t("crestEditText")}
              </button>
            </div> */}


            {/* <div>
              <button
                className="new-card-other-infos-btn"
                style={{
                  color: config.MUZEUM.textColor,
                  paddingBottom: '5px',
                  borderBottom: `1px solid ${config.MUZEUM.textColor}`,
                }}
              >
                {t('leaderBoardText')}
                <img src={brownRightArrow} />
              </button>
            </div> */}
          </div>
        </ul>
      </div>
    );
  };
  //#endregion
  return (
    <>
      <div className="new-card-container">
        <Header />
        {usersCrest.length > 0 ? (
          <div
            className="card-container-outer crest-container-outer"
            style={{ width: "100%" }}
          >
            <div className="wallet-container new-crest-container">
              <>
                {address && (
                  <div
                    className="back-to-cards"
                    onClick={() => navigate(`/wallet?address=${address}`)}
                  >
                    <img src={vectorBack} />
                    <p> {t("backToCards")}</p>
                  </div>
                )}{" "}
                <div
                  className="new-card-title"
                  style={{
                    color: config.MUZEUM.titleColor,
                    fontFamily: config.MUZEUM.extraBoldFont,
                    textAlign: "start",
                    marginBottom: "20px",
                  }}
                >
                  {insigneTokenURI && (
                    <h1
                      style={{
                        color: config.MUZEUM.titleColor,
                        fontFamily: config.MUZEUM.extraBoldFont,
                      }}
                    >
                      {insigneTokenURI.name}
                    </h1>
                  )}
                </div>
                <div className="card-container">
                  <div className="card-side" style={{ padding: 0 }}>
                    <a target="blank">
                      {/* <img src={usersCrest}/> */}
                      {usersCrest && (
                        <div
                          className="new-insigne-card"
                          onClick={toggleModal}
                          style={{
                            border: `2px solid ${config.MUZEUM.textColor}`,
                          }}
                        >
                          <img src={usersCrest[0]} />
                        </div>
                      )}
                    </a>
                  </div>

                  {textSide()}
                </div>
              </>
            </div>
          </div>
        ) : (
          <div className="loader">
            <ClipLoader
              color={"#999999"}
              loading={true}
              size={100}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )}
      </div>
      {isOpen && (
        <div className="new-card-modal" onClick={toggleModal}>
          <div className="crest-modal-view">
            {" "}
            <img src={usersCrest[0]} />
          </div>
        </div>
      )}
      <Footer style={{ paddingBottom: "20px" }} contentLoaded={usersCrest} />
    </>
  );
};

export default NewInsignePage;
