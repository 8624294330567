import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ClipLoader } from 'react-spinners';

import CardPage from './CardPage';
import Card from './Card';
import './walletStyles.scss';
import Header from './Header';
import Footer from './Footer';
import config from '../config.json';
import shield from '../assets/images/shield.svg';
import arrow from '../assets/images/arrow-right.png';

import { useExperienceCards } from './useExperienceCards';
import { LanguageContext } from '../context/languageContext';
import { useNftsLoad } from '../hooks/useNftsLoad';
import ButtonGroup from './button-group/ButtonGroup';
import { CardContext } from '../context/CardContext';

const Wallet = ({ address, isUser }) => {
  const { usersCrest } = useExperienceCards(address);
  const { collections, contentLoaded, error, mintedCollectionIds } =
    useNftsLoad(address);

  const { setCollectionList } = useContext(CardContext);
  const [cardIsShown, setCardIsShown] = useState(false);
  const [source, setSource] = useState('osszes');

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedArray, setSelectedArray] = useState([]);

  const [allCardsShown, setAllCardsShown] = useState(true);
  const [ownedCardsShown, setOwnedCardsShown] = useState(false);
  const [addressShown, setAddressShown] = useState(false);

  const navigate = useNavigate();

  const { t } = useTranslation();
  const langCtx = useContext(LanguageContext);

  const goToCardPage = (id) => {
    navigate(`/experience/${id}${address ? '?address=' + address : ''}`);
    // setSelectedIndex(index);
    // setSelectedArray(collections);
    // setCardIsShown(true);
    // setSource('osszes');
  };

  const goToCardPageForOwned = (id) => {
    navigate(`/experience/${id}${address ? '?address=' + address : ''}`);
    // setSelectedIndex(index);
    // setSelectedArray(collections.filter((item) => item.owned === true));
    // setCardIsShown(true);
    // setSource('sajat');
  };

  const displayCrestFullScreen = () => {
    setSelectedIndex(0);
    setSelectedArray(usersCrest);
    setCardIsShown(true);
    setSource('crest');
  };

  const hideCardPage = () => {
    setCardIsShown(false);
  };
  const tabValues = (e) => {
    if (e.target.value === 'allTab') {
      showAllCards();
    } else if (e.target.value === 'myCardsTab') {
      showOwnedCards();
    } else {
      showAddress();
    }
  };
  const showAllCards = () => {
    //clicks on the all cards tab
    setAllCardsShown(true);
    setOwnedCardsShown(false);
    setAddressShown(false);
    setSource('osszes');
    document.getElementById('osszes').classList.add('selected-tab');
    document.getElementById('sajat').classList.remove('selected-tab');
    document.getElementById('address').classList.remove('selected-tab');
  };

  const showOwnedCards = () => {
    //clicks on the my cards tab
    setOwnedCardsShown(true);
    setAllCardsShown(false);
    setAddressShown(false);
    setSource('sajat');
    document.getElementById('sajat').classList.add('selected-tab');
    document.getElementById('osszes').classList.remove('selected-tab');
    document.getElementById('address').classList.remove('selected-tab');
  };

  const showAddress = () => {
    //clicks on the wallet tab
    setAddressShown(true);
    setAllCardsShown(false);
    setOwnedCardsShown(false);
    document.getElementById('address').classList.add('selected-tab');
    document.getElementById('sajat').classList.remove('selected-tab');
    document.getElementById('osszes').classList.remove('selected-tab');
  };

  const link = `https://polygonscan.com/address/${address}`;

  const goToScanner = () => {
    navigate('/scanner');
  };

  const goToCrestPage = () => {
    window.open('https://insigne.csikimuzeum.ro/');
  };

  useEffect(() => {
    //only at the muzeum style needed
    //document.documentElement.style.backgroundColor = "white";
    if (cardIsShown) {
      document.body.style.backgroundColor = '#5A513F';
    } else {
      document.body.style.backgroundColor = '#fff';
    }
  }, [cardIsShown]);
  useEffect(() => {
    setCollectionList(collections);
  }, [collections]);

  useEffect(() => {
    if (!cardIsShown) {
      document.getElementById('osszes').classList.add('selected-tab');
    }
  }, []);

  useEffect(() => {
    if (!cardIsShown) {
      if (allCardsShown) {
        document.getElementById('osszes').classList.add('selected-tab');
      } else if (ownedCardsShown) {
        document.getElementById('sajat').classList.add('selected-tab');
      }
    }
  }, [cardIsShown]);

  return (
    <>
      {cardIsShown ? (
        <CardPage
          handleClick={hideCardPage}
          source={source}
          ownedCards={collections.filter((item) => item.owned === true)}
          allCards={selectedArray}
          selectedIndex={selectedIndex}
          address={address}
        />
      ) : (
        <>
          <div className="wallet">
            <Header crest={usersCrest} address={address} />
            <div className="wallet-container">
              <div className="wallet-title">
                <h1
                  style={{
                    color: config.MUZEUM.titleColor,
                    fontFamily: config.MUZEUM.extraBoldFont,
                    // WebkitTextStroke: `1px ${config.CAMELCODING.titleColor}`,
                  }}
                >
                  {t('title2')}
                </h1>
                <p
                  style={{
                    color: config.MUZEUM.textColor,
                    fontFamily: config.MUZEUM.semiboldFont,
                  }}
                >
                  {t('walletText')}
                </p>
              </div>

              <div className="tabs-holder">
                <div className="mobile-tabs">
                  <select
                    onChange={tabValues}
                    style={{ color: config.MUZEUM.titleColor }}
                  >
                    <option value={'allTab'}>{t('allTab')}</option>
                    <option value={'myCardsTab'}>{t('myCardsTab')}</option>
                    <option value={'walletAdrdessTab'}>
                      {t('walletAdrdessTab')}
                    </option>
                  </select>
                </div>
                <div
                  className="tab"
                  onClick={showAllCards}
                  id="osszes"
                  style={{ fontFamily: config.MUZEUM.titleFont }}
                >
                  {t('allTab')}
                </div>
                <div
                  className="tab"
                  onClick={showOwnedCards}
                  id="sajat"
                  style={{ fontFamily: config.MUZEUM.titleFont }}
                >
                  {t('myCardsTab')}
                </div>
                <div
                  className="tab"
                  onClick={showAddress}
                  id="address"
                  style={{ fontFamily: config.MUZEUM.titleFont }}
                >
                  {t('walletAdrdessTab')}
                </div>
              </div>

              {addressShown && (
                <div
                  className="address"
                  style={{ color: config.MUZEUM.textColor }}
                >
                  <p
                    className="wallet-address"
                    style={{
                      fontFamily: config.MUZEUM.font,
                      color: config.MUZEUM.textColor,
                    }}
                  >
                    {t('addressTabText')}
                  </p>
                  <a
                    href={link}
                    target="_blank"
                    className="address-link"
                    style={{ color: config.MUZEUM.textColor }}
                  >
                    {address}
                  </a>
                </div>
              )}
              {allCardsShown && (
                <div className="card-holder">
                  <>
                    {contentLoaded ? (
                      <div className="cards">
                        {collections.map((item, index) => {
                          return (
                            <Card
                              key={item.id}
                              cardItem={item}
                              onClick={
                                mintedCollectionIds.includes(item.id)
                                  ? () => goToCardPageForOwned(item.id)
                                  : () => goToCardPage(item.id)
                              }
                              isOwned={item.owned}
                              index={index}
                            />
                          );
                        })}
                      </div>
                    ) : (
                      <div className="loader">
                        <ClipLoader
                          color={'#999999'}
                          loading={!contentLoaded}
                          size={100}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      </div>
                    )}
                  </>
                </div>
              )}
              {ownedCardsShown && (
                <div className="card-holder">
                  <>
                    {contentLoaded ? (
                      <>
                        <div className="cards">
                          {collections.filter((item) => item.owned === true)
                            ?.length !== 0 ? (
                            collections
                              .filter((item) => item.owned === true)
                              .map((item, index) => {
                                //console.log(url, "metadata uri");
                                return (
                                  <Card
                                    key={index}
                                    name={item.name}
                                    cardItem={item}
                                    onClick={goToCardPageForOwned}
                                    isOwned={item.owned}
                                    index={index}
                                  />
                                );
                              })
                          ) : (
                            <div
                              className="no-cards"
                              style={{ color: config.MUZEUM.textColor }}
                            >
                              Még nem gyűjtöttél egyetlen kártyát sem.
                            </div>
                          )}
                        </div>
                      </>
                    ) : (
                      <div className="loader">
                        <ClipLoader
                          color={'#999999'}
                          loading={!contentLoaded}
                          size={100}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      </div>
                    )}
                  </>
                </div>
              )}
            </div>
            <Footer contentLoaded={contentLoaded} />
            <div className="wallet-button-bottom-container">
              <ButtonGroup address={address} crest={usersCrest} />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Wallet;
