import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Carousel from './Carousel';
import logout from '../assets/images/x-circle.png';

const CardPage = ({
  address,
  handleClick,
  allCards,
  selectedIndex,
  source,
}) => {
  const navigate = useNavigate();

  //only at muzeum styles needed
  useEffect(() => {
    //document.documentElement.style.backgroundColor = "#5A513F";
    document.body.style.backgroundColor = '#5A513F';
  }, []);

  function urlify(text) {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function (url) {
      return '<a href="' + url + '" target="_blank">' + url + '</a>';
    });
  }

  useEffect(() => {
    window.history.pushState(
      null,
      null,
      `${window.location.pathname}?address=${address}`
    );
    window.addEventListener('popstate', handleClick);

    window.scrollTo(0, 0);

    return () => {
      window.removeEventListener('popstate', handleClick);
    };
  }, [navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className='single-card'>
        {source === 'crest' ? (
          <>
            <div className='title-button'>
              <img src={logout} className='close-btn' onClick={handleClick} />
            </div>
            <div
            className='crest-fullscreen-container'
              dangerouslySetInnerHTML={{
                __html: allCards[0],
              }}
            ></div>
          </>
        ) : (
          <Carousel
            handleClick={handleClick}
            allCards={allCards}
            selectedIndex={selectedIndex}
          />
        )}
      </div>
    </>
  );
};

export default CardPage;
