import React, { useContext } from 'react';
import exit from '../assets/images/x-circle.png';
import shield from '../assets/images/main-shield.svg';
import { useNavigate } from 'react-router-dom';
import { ClickSourceContext } from '../context/clickSourceContext';
import { useTranslation } from 'react-i18next';

const CrestPage = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  // const ctx = useContext(ClickSourceContext);
  // const walletAddress = ctx.scannedAddress;
  const walletAddress = localStorage.getItem('address');

  const goToWizard = () => {
    window.open('https://insigne.camelcoding.xyz/', '_blank');
  };

  const goBackToWallet = () => {
    navigate(`/wallet?address=${walletAddress}`);
  };

  return (
    <div className="crest-page">
      <div className="crest-title-holder">
        <h2>{t('createCrest')}</h2>
        <img src={exit} onClick={goBackToWallet}></img>
      </div>
      <p className="crestpage-text">{t('createCrestDescription')}</p>
      <img src={shield} className="crest-img"></img>
      <button className="crestpage-button" onClick={goToWizard}>
        {t('createBtn')}
      </button>
    </div>
  );
};

export default CrestPage;
