import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import config from '../config.json';
import { LanguageContext } from '../context/languageContext';

const Card = ({ onClick, cardItem, isOwned, index }) => {
  const langCtx = useContext(LanguageContext);
  //console.log(collectionUri, 'collection uri')

  const [titleHu, setTitleHu] = useState('élmény neve hu');
  const [titleRo, setTitleRo] = useState('élmény neve ro');
  const [titleEn, setTitleEn] = useState('élmény neve en');
  const [image, setImg] = useState('');

  const { t } = useTranslation();

  useEffect(() => {
    // console.log(response.data, "metadata");
    setTitleHu(cardItem.nameHu);
    setTitleRo(cardItem.nameRo);
    setTitleEn(cardItem.nameEn);
    setImg(cardItem.image);
  }, []);

  return (
    <>
      <div className='card' style={{ opacity: isOwned ? '1' : '0.5' }}>
        <img
          className={'card-image'}
          src={image}
          onClick={() => {
            langCtx.language === 'hu'
              ? onClick(index)
              : langCtx.language === 'ro'
              ? onClick(index)
              : onClick(index);
          }}
        />
        <div className='card-content-holder'>
          <h5
            className='card-title'
            style={{
              color: config.MUZEUM.titleColor,
              fontFamily: config.MUZEUM.extraBoldFont,
              textAlign: 'start',
            }}
          >
            {langCtx.language === 'hu'
              ? titleHu
              : langCtx.language === 'ro'
              ? titleRo
              : titleEn}
          </h5>
          <div className='minted-supply-box'>{`${cardItem.mintedSupply} `}{t("nrOfMinted")}</div>
        </div>
      </div>
    </>
  );
};

export default Card;
