import React from "react";
import { useTranslation } from "react-i18next";

import shieldImage from "../../assets/images/button-group/shield.png";

import "./ButtonGroup.css";
import { useNavigate } from "react-router-dom";

const ButtonGroup = ({ crest, address }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goToScanner = () => {
    navigate("/scanner");
  };
  const goToCrestPage = () => {
    navigate(`/insigne?address=${address}`);
  };
  const goToInsigneProject = () => {
    window.open("https://insigne.csikimuzeum.ro/");
  };

  return (
    <div className="button-group-box">
      <button className="primary-button" onClick={goToScanner}>
        {" "}
        {t(`scanButtonText2`)}
      </button>
      {address && (
        <button
          className="secondary-button"
          onClick={() => {
            crest.length > 0 ? goToCrestPage() : goToInsigneProject();
          }}
        >
          {crest.length > 0 ? (
            <>
              <div className="button-crest-icon">
                <img src={crest[0]} />
              </div>
              {t(`existCrest`)}
            </>
          ) : (
            <>
              <img src={shieldImage} alt="crest" />
              {t(`createCrest`)}
            </>
          )}
        </button>
      )}
    </div>
  );
};

export default ButtonGroup;
