import WalletPage from '../views/WalletPage.jsx';
import Home from '../views/Home.jsx';
import LanguagePage from '../views/LanguagePage.jsx';
import Scanner from '../views/Scanner.jsx';
import CrestPage from '../views/CrestPage.jsx';
import NewCardPage from '../views/newCardPage/NewCardPage.jsx';
import NewInsignePage from '../views/newCardPage/NewInsignePage.jsx';

const routes = [
  {
    path: '/',
    component: <LanguagePage />,
    exact: true,
  },
  {
    path: '/experience/:collectionId',
    component: <NewCardPage />,
    exact: true,
  },
  {
    path: '/insigne',
    component: <NewInsignePage />,
    exact: true,
  },
  {
    path: '/home',
    component: <Home />,
    exact: true,
  },
  {
    path: '/wallet',
    component: <WalletPage />,
    exact: true,
  },
  {
    path: '/scanner',
    component: <Scanner />,
    exact: true,
  },
  {
    path: '/crest',
    component: <CrestPage />,
    exact: true,
  },
];

export default routes;
